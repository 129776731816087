import ReactDomServer from 'react-dom/server';
import PropTypes from 'prop-types';
import isEmail from 'validator/lib/isEmail';
import context from '../../../shared/component/context';
import checkPasswordRules from '../../../shared/lib/check-password-rules';
import EmailField from '../../../shared/component/form/email-field-component';
import EnhancedTextField from '../../../shared/component/form/enhanced-text-field-component';
import FormFieldsContainer from '../../../shared/component/form/fields-container-component';
import getErrorMessage from '../../../shared/lib/get-graphql-error-message';
import getRouteParam from '../../../shared/lib/get-route-param';
import LoadingIndicator from '../../../shared/component/loading-indicator-component';
import PageFormHandler from '../../../shared/component/page-form-handler-component';
import PasswordField from '../../../shared/component/form/password-field-component';
import PublicForm, {
  BottomLink,
  inputStyle
} from '../../../shared/component/public/public-form-component';
import PublicPage from '../../../shared/component/public/public-page-component';
import {SignInLink} from '../../../shared/component/link/links-to-desktop-component';
import StandardRaisedButton from '../../../shared/component/button/standard-raised-button-component';
import TermsOfServiceLink from '../../../shared/component/link/links-to-website';
import {fontWeight, inputMaxLength} from '../../../shared/style/variables';
import {blue} from '../../../shared/style/colors';

class SignUpComponent extends PageFormHandler {
  constructor(props) {
    super(props);

    const token = getRouteParam('token', props.location);

    this.state = {
      token,
      email: '',
      firstName: '',
      isInvitation: !!token,
      lastName: '',
      password: '',
      showError: {
        email: false,
        firstName: false,
        password: false
      }
    };

    this._doSubmitSignUpForm = this._doSubmitSignUpForm.bind(this);
    this._generateSignUpPage = this._generateSignUpPage.bind(this);
  }

  componentDidMount() {
    if (this.state.isInvitation) {
      this.props.doGetPendingInvitation(this.state.token);
    }
  }

  componentWillUpdate(nextProps) {
    if (
      this.props.submitting &&
      !nextProps.submitting &&
      nextProps.errors &&
      typeof nextProps.errors !== 'object'
    ) {
      nextProps.errors.forEach((error) => this._updateFieldErrorStatus(error, true));
    }
  }

  _doSubmitSignUpForm() {
    const {language} = this.props;
    const {firstName, lastName, password, token} = this.state;
    const email = this.props.email || this.state.email;

    const isEmailValid = isEmail(email);
    const isPasswordValid = checkPasswordRules(password).isValid;

    this.setState(
      {
        showError: {
          email: !isEmailValid,
          firstName: !firstName,
          password: !isPasswordValid
        }
      },
      () =>
        this.props.doSubmitSignUp(
          email,
          firstName,
          lastName,
          language,
          password,
          token,
          isEmailValid && firstName && isPasswordValid
        )
    );
  }

  _generateSignUpPage(props, children) {
    return (
      <PublicPage
        dataTestId="signup"
        language={this.props.language}
        title={this.context.i18n.t(
          `signUp.${this.state.isInvitation ? 'invitation' : 'autoJoin'}.title`
        )}
        doUIEndMount={this.props.doUIEndMount}
        doUIUpdateLanguage={this.props.doUIUpdateLanguage}
        {...props}
      >
        {children}
      </PublicPage>
    );
  }

  render() {
    const {i18n} = this.context;
    const {
      businesses,
      errors,
      invitingUserDisplayName,
      loading,
      organizationName,
      signedUp,
      submitting
    } = this.props;
    const {firstName, isInvitation, lastName, password, showError} = this.state;

    const errorKey = errors.length ? errors[0] : null;

    const email = isInvitation ? this.props.email : this.state.email;
    const i18nPrefix = `signUp.${isInvitation ? 'invitation' : 'autoJoin'}`;

    const globalErrorMessage = getErrorMessage(errors, i18nPrefix, i18n);

    if (loading) {
      return this._generateSignUpPage(
        {
          showFooter: false
        },
        <LoadingIndicator />
      );
    }

    // Screen 2 : the confirmation
    if (signedUp) {
      return this._generateSignUpPage(
        null,
        <PublicForm
          buttons={<SignInLink />}
          description={i18n.t('signUp.signedUp')}
          descriptionDataTestId="signed-up"
          title={i18n.t(`${i18nPrefix}.title`)}
        />
      );
    }

    // Screen 2b : the global errors
    if (isInvitation && errorKey) {
      return this._generateSignUpPage(
        null,
        <PublicForm
          buttons={<SignInLink />}
          globalErrorMessage={globalErrorMessage}
          title={i18n.t(`${i18nPrefix}.title`)}
        />
      );
    }

    // Screen 1 : the form
    return this._generateSignUpPage(
      {
        description: isInvitation
          ? i18n.t('signUp.invitation.description', {
              invitingUserDisplayName,
              organizationName: ReactDomServer.renderToStaticMarkup(
                <span style={{fontWeight: fontWeight.bold, color: blue}}>{organizationName}</span>
              ),
              teams: ReactDomServer.renderToStaticMarkup(
                <span style={{fontWeight: fontWeight.bold}}>{businesses.join(', ')}</span>
              )
            })
          : null
      },
      <PublicForm
        doSubmit={this._doSubmitSignUpForm}
        globalErrorMessage={globalErrorMessage}
        submitting={submitting}
        submittingLabel={i18n.t('form.submitting')}
        title={i18n.t(`${i18nPrefix}.title`)}
        bottomLinks={[
          <BottomLink key="signIn" labelKey="public.alreadyHaveAnAccount" link={<SignInLink />} />,
          <BottomLink
            key="legal"
            link={i18n.t('signUp.legalLinksLabel', {
              privacyPolicyLink: ReactDomServer.renderToStaticMarkup(
                <a
                  data-test-id="privacy-policy-link"
                  href="https://www.instaply.com/us/legal/privacy-policy"
                  target="_blank"
                  rel="noreferrer"
                >
                  {i18n.t('signUp.autoJoin.privacyPolicy')}
                </a>
              ),
              termsOfServiceLink: ReactDomServer.renderToStaticMarkup(
                <TermsOfServiceLink>{i18n.t('signUp.autoJoin.termsOfService')}</TermsOfServiceLink>
              )
            })}
          />
        ]}
        buttons={
          submitting ? null : <StandardRaisedButton label={i18n.t(`${i18nPrefix}.submitButton`)} />
        }
      >
        <FormFieldsContainer>
          <EnhancedTextField
            data-test-id="firstname-input"
            autoComplete="given-name"
            autoFocus
            disabled={submitting}
            errorText={showError.firstName ? i18n.t('form.thisFieldIsRequired') : null}
            floatingLabelText={i18n.t('form.firstName')}
            hintText={i18n.t('form.firstName')}
            maxLength={inputMaxLength.firstName}
            name="firstName"
            onBlur={this._updateFieldErrorStatus}
            onChange={this._onFieldChange}
            onFocus={this._updateFieldErrorStatus}
            style={inputStyle}
            value={firstName}
          />
          <EnhancedTextField
            data-test-id="lastname-input"
            autoComplete="family-name"
            disabled={submitting}
            floatingLabelText={i18n.t('form.lastName')}
            hintText={i18n.t('form.lastName')}
            maxLength={inputMaxLength.lastName}
            name="lastName"
            onChange={this._onFieldChange}
            value={lastName}
            style={inputStyle}
          />
          <EmailField
            disabled={isInvitation || submitting}
            errorText={showError.email ? i18n.t('form.emailAddressRules') : null}
            onBlur={this._updateFieldErrorStatus}
            onChange={this._onFieldChange}
            onFocus={this._updateFieldErrorStatus}
            value={email}
          />
          <PasswordField
            disabled={submitting}
            errorText={showError.password ? i18n.t('form.passwordShouldMatchSecurityRules') : null}
            onBlur={this._updateFieldErrorStatus}
            onChange={this._onFieldChange}
            onFocus={this._updateFieldErrorStatus}
            showRulesCheck
            value={password}
          />
        </FormFieldsContainer>
      </PublicForm>
    );
  }
}

SignUpComponent.contextTypes = context;

SignUpComponent.propTypes = {
  businesses: PropTypes.arrayOf(PropTypes.any).isRequired,
  email: PropTypes.string,
  errors: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
  invitingUserDisplayName: PropTypes.string.isRequired,
  language: PropTypes.string,
  loading: PropTypes.bool.isRequired,
  organizationName: PropTypes.string.isRequired,
  signedUp: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  doGetPendingInvitation: PropTypes.func.isRequired,
  doSubmitSignUp: PropTypes.func.isRequired,
  doUIEndMount: PropTypes.func.isRequired,
  doUIUpdateLanguage: PropTypes.func.isRequired
};

export default SignUpComponent;
